import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import ReactGA from 'react-ga4';

import FileSelector from '../components/FileSelector';
import { env } from '../env';


const defaultState = {
  name: null,
  email: null,
  phone: null,
  comment: null,
  files: [],
  contents: [],
  nameError: null,
  emailError: null,
  filesError: null,
  successMessage: null,
};

export default class SubmitPage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if (name) {
      this.setState({
        [name]: value,
      });
    }
  }

  onFilesAdded(files, contents) {
    this.setState({ files });
    this.setState({ contents });
  }

  validate() {
    if (!this.state.files?.length) {
      this.setState({ filesError: 'Resume is required' });
      return false;
    } else
      this.setState({ filesError: null });

    if (!this.state.name) {
      this.setState({ nameError: 'Name is required' });
      return false;
    } else
      this.setState({ nameError: null });

    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit: ', this.state);

    if (this.validate()) {
      const url = `${env.baseUrl}/v1/submit-email`;
      const req = new XMLHttpRequest();
      req.open('POST', url);
      req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          // Request finished. Do processing here.
          console.log(`Submit reponse: `, req.response);
          this.setState({ successMessage: 'Thanks for submitting with us' });

          ReactGA.event({
            category: 'Email',
            action: 'Submit Resume',
            label: this.state.email || this.state.name,
          });
        }
      }

      const payload = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        comment: this.state.comment,
        files: this.state.files,
        contents: this.state.contents,
      };
      req.send(JSON.stringify(payload));
    }
  }

  render() {
    const bgClass = this.props.bg || 'none';
    return <section id="submit" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>


        <h1>Submit Your Resume</h1>
        <p>We believe in our candidates and are heavily invested in our clients' success.</p>
        <p>Please contact us at <a href = "mailto:info@lateral-match.com">info@lateral-match.com</a> or by filling out the form below, and a team member will be in touch.</p>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <TextField name="name" label="Name" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.nameError }</Typography>

          <TextField name="email" label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>

          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="comment" label="Message" variant="outlined" multiline rows={4} fullWidth onChange={this.handleInputChange} />

          <span>Resume</span>
          <FileSelector onChange={this.onFilesAdded} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.filesError }</Typography>

          <Button type="submit" variant="contained">Submit</Button>
        </Box>

      </Container>

    </section>;

  }
}

