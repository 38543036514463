import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import ReactGA from 'react-ga4';

import { env } from '../env';


const defaultState = {
  firstName: null,
  lastName: null,
  school: null,
  phone: null,
  email: null,
  comment: null,
  emailError: null,
  commentError: null,
  successMessage: null,
};

export default class Join extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  validate() {
    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    if (!this.state.comment) {
      this.setState({ commentError: 'Comment or Message is required' });
      return false;
    } else
      this.setState({ commentError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit: ', this.state);

    if (this.validate()) {
      const url = `${env.baseUrl}/v1/join-email`;
      const req = new XMLHttpRequest();
      req.open('POST', url);
      req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          // Request finished. Do processing here.
          console.log(`Join reponse: `, req.response);
          this.setState({ successMessage: 'Thanks for expressing interest in us' });

          ReactGA.event({
            category: 'Email',
            action: 'Join Us',
            label: this.state.email,
          });
        }
      }


      const payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        school: this.state.school,
        phone: this.state.phone,
        email: this.state.email,
        comment: this.state.comment,
      };
      req.send(JSON.stringify(payload));
    }
  }

  render() {
    const bgClass = this.props.bg || 'none';
    return <section id="join" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

      <h1>Join Lateral Match</h1>
      <p>
Interested in joining us as an attorney recruiter?
      </p>
      <p>
We’d love to hear from you!
      </p>
      <p>
We are always willing to have a conversation with anyone with either significant lateral attorney hiring experience or current attorneys with stellar academic and professional credentials, who are interested in transitioning to a career in legal recruiting.
      </p>
      <p>
Please fill out the form below and we will contact you shortly.
      </p>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }} disableGutters={true} >
            <TextField name="firstName" label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="lastName" label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true}>
            <TextField name="firstName" label="First name" variant="outlined" sx={{ width: '48%' }} onChange={this.handleInputChange} />
            <TextField name="lastName" label="Last name" variant="outlined" sx={{ width: '48%', ml: '1.65rem !important' }} onChange={this.handleInputChange} />
          </Container>

          <TextField name="school" label="Law School" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="email" label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>
          <TextField name="comment" label="Comment or Message" variant="outlined" multiline rows={4} fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.commentError }</Typography>

          <Button type="submit" variant="contained">Contact Us</Button>
          <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>

        </Box>

      </Container>
    </section>;
  }
}

