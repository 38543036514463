import React, { Component } from 'react';
import { Nav, NavLink, NavMenu } from "./LogoBarElements";

import './LogoBar.css';
//import matchLogo from '../assets/LateralMatch_Logo.png';
import matchLogo from '../assets/logo-blue2.png';

export default class LogoBar extends Component {
  render() {
    return <div className="logobar">
      <a href="/"><img className="match-logo" src={matchLogo} alt="Lateral Match logo" width="120" height="50" /></a>

      <Nav className="logobar-nav">
        <NavMenu>
          <NavLink to="/contact">Contact Us</NavLink>
        </NavMenu>
      </Nav>
    </div>;
  }
}

