import React, { Component } from 'react';
import { Container } from "@mui/material";

import AmandaFinley from '../assets/AmandaFinley.jpg';
import './Founders.css';


export default class Founders extends Component {
  render() {
    const bgClass = this.props.bg || 'none';
    return <section className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

        <h1>About Our Founder</h1>
        <p style={{display:'none'}}>Lateral Match was founded by alumni of top law schools, who pursued federal clerkships and <i>AmLaw</i> 100 firm associate positions before using their skills and knowledge of the legal market to match top legal talent with the law firms that best suit them.</p>

        <h2>Amanda Finley</h2>
        <p><img src={AmandaFinley} class="headshot" alt="Amanda Finley" />
        Before founding Lateral Match, Amanda Finley earned her J.D. at the University of Florida Levin College of Law (#8 ranked public law school in the U.S.), <i>magna cum laude</i>, with a 3.78 GPA. She was awarded Order of the Coif; President of the Florida Association for Women Lawyers; Executive Articles Editor of the Journal of Law and Public Policy; Finalist and received the Best Brief Award for the Duberstein Moot Court Competition (competed two years consecutively); Finalist in Cristol, Paskay, Kahn Moot Court Cup; participated in the Willem C. Vis International Commercial Arbitration Moot in Vienna, Austria; received the American Bankruptcy Institute's Medal of Excellence; and was a Teaching Assistant, Legal Research and Writing and Appellate Advocacy.</p>

        <p>Prior to that, Amanda obtained her Bachelor of Arts in Political Science with minors in Mass Communications and Environmental Studies from the University of Florida (#6 ranked public university in the U.S.), <i>cum laude</i>, with a 3.84 GPA. She was awarded multiple scholarships and had significant community involvement, including Guardian ad Litem and Big Brothers Big Sisters.</p>

        <p>After being admitted to the Florida Bar, Amanda served as a Federal Judicial Law Clerk to the Honorable Raymond B. Ray in the Southern District of Florida for four years. Thereafter, she was a Litigation Associate at an <i>AmLaw</i> 50 firm and then a specialized litigation boutique for 5 years focusing on international commercial litigation and insolvency matters.  Amanda founded Lateral Match to help attorneys nationwide find a law firm that is the best fit for their career goals and personality where they can thrive and be successful long term. Feel free to reach out to Amanda at (305) 330-4488 or <a href="mailto:afinley@lateral-match.com">afinley@lateral-match.com</a> if you would like to find your perfect law firm lateral match.</p>

      </Container>
    </section>;

  }
}

