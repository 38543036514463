import React, { useState } from "react";
import { Button, Link, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import './MobileMenu.css';
import matchLogo from '../assets/LateralMatch_Logo.png';


export default function MobileMenu() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="mobile-menu">
      <a href="/"><img className="mobile-logo" src={matchLogo} alt="Lateral Match logo" /></a>

      <Button
        id="mobile-menu-btn"
        aria-label="mobile menu"
        aria-haspopup="true"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>

      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'mobile-menu-btn' }}
      >
        <MenuItem href="/#" component={Link} onClick={handleClose}>Home</MenuItem>
        <MenuItem href="/mission" component={Link} onClick={handleClose}>Mission</MenuItem>
        <MenuItem href="/employers" component={Link} onClick={handleClose}>Legal Employers</MenuItem>
        <MenuItem href="/founders" component={Link} onClick={handleClose}>Founder</MenuItem>
        <MenuItem href="/recruiters" component={Link} onClick={handleClose}>Recruiters</MenuItem>
        <MenuItem href="/testimonials" component={Link} onClick={handleClose}>Testimonials</MenuItem>
        <MenuItem href="/contact" component={Link} onClick={handleClose}>Contact Us</MenuItem>
        <MenuItem href="/join" component={Link} onClick={handleClose}>Join Us</MenuItem>
      </Menu>
    </div>
  );
}

