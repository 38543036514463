import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

/*
.menubar {
  background-color: #1664a0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
}

.menu-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50rem;
}

.menu-items a {
  color: white;
}
*/

export const Nav = styled.nav`
  background-color: #1664a0;
  color: white;
  display: flex;
  justify-content: center;
  min-height: 2rem;
  width: 100%;
  z-index: 12;
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
	display: none;
  }
  width: 50rem;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  color: white;
  &:hover {
    text-decoration: none;
  }
  &.active {
	font-weight: bold;
  }
`;

