import React, { Component } from 'react';
import { Link, Container } from "@mui/material";
import styled from "styled-components";

import './Hero.css';
import ImageCarousel from "./ImageCarousel";

//import heroImage from '../assets/lawyers.jpg';
//import heroImage from '../assets/handshake.png';


const H1 = styled.h1`
  margin: 0;
`;

export default class Hero extends Component {

  images = [
    `${window.location.origin}/puzzle-handshake.png`,
    `${window.location.origin}/lawyers2.png`,
    `${window.location.origin}/lawyers.png`,
    `${window.location.origin}/handshake.png`
  ];

  render() {
    return <section className="home">
      <div className="home-section-wrapper">
        <Container sx={{
          maxWidth: { xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
          padding: { xs: '0 2rem', sm: '0 2rem', md: 0, lg: 0, xl: 0 }
        }} disableGutters={true}>

          <div className="content-left">
            <H1 className="content-title">We Help Top Legal Talent</H1>
            <h1 className="content-subtitle">Find Their Perfect Lateral Match</h1>
            <p className="content-p">We use our interpersonal skills and deep knowledge of the industry to match the country’s top attorneys with positions that best suit them</p>
            <p className="content-p">Submit your resume to have one of our recruiters reach out to you for a consultation</p>
            <div className="hero-actions">
              <Link href="/submit" className="link-button">Submit Your Resume</Link>
            </div>
          </div>
        </Container>

        <Container sx={{
            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
            width: { xs: '100%', sm: '100%', md: 'calc(50% - 2rem)', lg: 'calc(50% - 3rem)', xl: 'calc(50% - 3rem)' },
            mt: 'auto'
          }}
          disableGutters={true}
        >
          <ImageCarousel className="hero-image" rotateTime="10000" images={this.images}/>
        </Container>
      </div>
    </section>;
  }
}

