import { Component } from 'react';
import { Container } from "@mui/material";

export default class Terms extends Component {
  render() {
    return <section className="page-section">
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
      }}>
        <h1>Terms and Conditions</h1>
        <p>Terms and Conditions goes here...</p>
        <p>Check out <a href="https://www.reviewmyclaim.co.uk/terms-of-conditions-and-cookies-policy">reviewmyclaim.co.uk/terms-of-conditions-and-cookies-policy</a> for an example...</p>
        <p>May be a bit overkill... we could trim it down</p>

      </Container>
    </section>;
  }
}

