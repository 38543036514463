import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import ReactGA from 'react-ga4';

import { env } from '../env';


const defaultState = {
  firstName: null,
  lastName: null,
  company: null,
  phone: null,
  email: null,
  comment: null,
  error: null,
  companyError: null,
  emailError: null,
  commentError: null,
  successMessage: null,
};


export default class Employers extends Component {
 
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if (name) {
      this.setState({
        [name]: value,
      });
    }
  }

  validate() {
    if (!this.state.company) {
      this.setState({ companyError: 'Company / Firm is required' });
      return false;
    } else
      this.setState({ companyError: null });

    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    if (!this.state.comment) {
      this.setState({ commentError: 'Comment or Message is required' });
      return false;
    } else
      this.setState({ commentError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit: ', this.state);

    if (this.validate()) {
      const url = `${env.baseUrl}/v1/employers-email`;
      const req = new XMLHttpRequest();
      req.open('POST', url);
      req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          // Request finished. Do processing here.
          console.log(`Employers reponse: `, req.response);
          this.setState({ successMessage: 'Thanks for contacting us' });

          ReactGA.event({
            category: 'Email',
            action: 'Employer Email',
            label: this.state.company,
          });
        }
      }

      const payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        company: this.state.company,
        phone: this.state.phone,
        email: this.state.email,
        comment: this.state.comment,
      };
      req.send(JSON.stringify(payload));
    }
  }

  render() {
    const bgClass = this.props.bg || 'none';
    return <section id="employers" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '1rem 1rem 0', sm: '1rem 1rem 0', md: '1rem 0 0', lg: '1rem 0 0', xl: '1rem 0 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

        <h1>Legal Employers</h1>
        <p>Lateral Match works with all legal employers - <i>AmLaw</i> 200, mid-sized, boutique and small law firms as well as in-house legal departments -  throughout the country.</p>
        <p>We understand that every placement requires a significant investment, so it is important that every match between an attorney and legal employer be as secure as possible. That’s where our team truly excels.</p>
        <p>Please contact us to discuss your hiring needs.</p>
        <p>We would love to find you your perfect match.</p>

      </Container>

      <Container sx={{
        maxWidth: { xs: 'calc(100% - 1rem)', sm: 'calc(100% - 1rem)', md: 800, lg: 800, xl: 800 },
        margin: { xs: '0 0.5rem', sm: '0 0.5rem', md: '0 auto', lg: '0 auto', xl: '0 auto' },
        pb: '2rem',
      }} disableGutters={true}>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true} >
            <TextField name="firstName" label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="lastName" label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }} disableGutters={true}>
            <TextField name="firstName" label="First name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%' }} />
            <TextField name="lastName" label="Last name" variant="outlined" onChange={this.handleInputChange} sx={{ width: '48%', ml: '1.65rem !important' }} />
          </Container>

          <TextField name="company" label="Company / Firm" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.companyError }</Typography>
          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="email" label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>
          <TextField name="comment" label="Comment or Message" variant="outlined" multiline rows={4} fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.commentError }</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', color: red[900] }}>
            <Button type="submit" variant="contained">Contact Us</Button>
            <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.error }</Typography>
            <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
          </Box>
        </Box>

      </Container>

    </section>;

  }
}


