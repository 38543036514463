import { Component } from 'react';
import { Container } from "@mui/material";
import Recruiters from '../components/Recruiters';
import bgImage from '../assets/boardroom.png';

export default class RecruitersPage extends Component {
  render() {
    return <Container sx={{ height: 'calc(100vh - 16rem)', overflowY: 'auto',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <Recruiters />
      </Container>;
  }
}

