import React, { Component } from 'react';
import { Container } from "@mui/material";

import './Header.css';
import MobileMenu from './MobileMenu';
import LogoBar from './LogoBar';
import MenuBar from './MenuBar';


export default class Header extends Component {
  render() {
    return <div>
      <Container sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }} disableGutters={true} >
        <MobileMenu />
      </Container>

      <Container sx={{ display: { xs: 'none', sm: 'none', md: 'contents', lg: 'contents', xl: 'contents' } }} disableGutters={true}>
        <LogoBar />
        <MenuBar />
      </Container>
    </div>;
  }
}

