import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { amber } from '@mui/material/colors';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './pages/HomePage';
import MissionPage from './pages/MissionPage';
import AboutPage from './pages/AboutPage';
import SearchPage from './pages/SearchPage';
import EmployersPage from './pages/EmployersPage';
import ContactPage from './pages/ContactPage';
import JoinPage from './pages/JoinPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import SubmitPage from './pages/SubmitPage';
import RecruitersPage from './pages/RecruitersPage';
import FoundersPage from './pages/FoundersPage';
import TestimonialsPage from './pages/TestimonialsPage';
import NotFoundPage from './pages/NotFoundPage';

// Google Analytics
ReactGA.initialize('G-B9Z2RL4KNB');

const theme = createTheme({
  palette: {
    primary: {
      main: '#1664a0'
    },
    secondary: {
      main: amber[600]
    }
  }
});

export default function App() {
  const location = useLocation();

  const setTitleDescr = (title, descr) => {
    // og: is the Open Graph protocol https://ogp.me
    // DC is the DublinCore metadata format from w3c

    document.title = title;
    document.querySelector('meta[name="og:title"]').setAttribute('content', title);
    document.querySelector('meta[name="DC.title"]').setAttribute('content', title);

    document.querySelector('meta[name=description]').setAttribute('content', descr);
    document.querySelector('meta[name="og:description"]').setAttribute('content', descr);
    document.querySelector('meta[name="DC.description"]').setAttribute('content', descr);

    document.querySelector('meta[name="og:url"]').setAttribute('content', window.location.href);
  };

  useEffect(() => {
    // Search Engine Optimization (SEO)
    switch (window.location.pathname) {
      case '/mission':
        setTitleDescr('Mission | Lateral Match', 'The mission of lateral-match.com is: We Help Top Legal Talent Find Their Perfect Lateral Match');
        break;
      case '/employers':
        setTitleDescr('Employers | Lateral Match', 'Lateral Match works with all legal employers - AmLaw 200, mid-sized, boutique and small law firms as well as in-house legal departments - throughout the country.');
        break;
      case '/recruiters':
        setTitleDescr('Recruiters | Lateral Match', 'Our recruiters have placed attorneys at top law firms throughout the country. We pair our legal market expertise with an in-depth tailored analysis of each candidate’s background and goals to maximize their next career move.');
        break;
      case '/contact':
        setTitleDescr('Contact Us | Lateral Match', 'We Help Top Legal Talent Find Their Perfect Lateral Match');
        break;
      case '/join':
        setTitleDescr('Join Us | Lateral Match', 'Interested in joining us as an attorney recruiter?  We help top recruiters find their perfect Lateral Match');
        break;
      case '/submit':
        setTitleDescr('Submit Your Resume | Lateral Match', 'Submit your resume to Lateral Match');
        break;
      case '/privacy':
        setTitleDescr('Privacy | Lateral Match', 'Privacy policy for Lateral Match');
        break;
      case '/':
      default:
        setTitleDescr('Lateral Match', 'We Help Top Legal Talent Find Their Perfect Lateral Match');
        break;
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Header />

      <Routes>
        <Route exact path='/' element={<HomePage/>} />
        <Route path='/employers' element={<EmployersPage/>} />
        <Route path='/mission' element={<MissionPage/>} />
        <Route path='/about' element={<AboutPage/>} />
        <Route path='/search' element={<SearchPage/>} />
        <Route path='/contact' element={<ContactPage/>} />
        <Route path='/join' element={<JoinPage/>} />
        <Route path='/privacy' element={<PrivacyPage/>} />
        <Route path='/terms' element={<TermsPage/>} />
        <Route path='/submit' element={<SubmitPage/>} />
        <Route path='/recruiters' element={<RecruitersPage/>} />
        <Route path='/founders' element={<FoundersPage/>} />
        <Route path='/testimonials' element={<TestimonialsPage/>} />
        <Route path='*' element={<NotFoundPage/>} />
      </Routes>

      <Footer />
    </ThemeProvider>
  );
}

