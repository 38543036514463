import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import ReactGA from 'react-ga4';

import { env } from '../env';


const defaultState = {
  firstName: null,
  lastName: null,
  company: null,
  school: null,
  phone: null,
  email: null,
  comment: null,
  emailError: null,
  commentError: null,
  successMessage: null,
};

export default class ContactPage extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    if (name) {
      this.setState({
        [name]: value,
      });
    }
  }

  validate() {
    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    } else
      this.setState({ emailError: null });

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(this.state.email) === false) {
      this.setState({ emailError: 'Email is invalid' });
      return false;
    } else
      this.setState({ emailError: null });

    if (!this.state.comment) {
      this.setState({ commentError: 'Comment or Message is required' });
      return false;
    } else
      this.setState({ commentError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit: ', this.state);

    if (this.validate()) {
      const url = `${env.baseUrl}/v1/contact-email`;
      const req = new XMLHttpRequest();
      req.open('POST', url);
      req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          console.log(`Contact reponse: `, req.response);
          this.setState({ successMessage: 'Thanks for contacting us' });

          ReactGA.event({
            category: 'Email',
            action: 'Contact Us',
            label: this.state.email || this.state.company,
          });
        }
      }

      const payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        company: this.state.company,
        school: this.state.school,
        phone: this.state.phone,
        email: this.state.email,
        comment: this.state.comment,
      };
      req.send(JSON.stringify(payload));
    }
  }

  render() {
    const bgClass = this.props.bg || 'none';
    return <section id="contact" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>

        <h1>Contact Us</h1>
        <p>We believe in our candidates and are heavily invested in our industry clients' success.</p>
        <p>Please contact us at <a href = "mailto:info@lateral-match.com">info@lateral-match.com</a>, calling or by filling out the form below, and a team member will be in touch.</p>
        <p>Miami Office: (305) 330-4488</p>
        <p>New York Office: (646) 374-4948</p>
        <p>Chicago Office: (320) 493-3770</p>
        <p>Dallas Office: (352) 222-2080</p>
        <p>Washington DC Office: (202) 900-9955</p>
        <p>Los Angeles Office: (310) 507-1101</p>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <Container sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }} disableGutters={true} >
            <TextField name="firstName" label="First name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
            <TextField name="lastName" label="Last name" variant="outlined" fullWidth onChange={this.handleInputChange} sx={{ m: '0.5rem 0' }} />
          </Container>

          <Container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, '& > :not(style)': { m: '0.5rem 0' } }} disableGutters={true}>
            <TextField name="firstName" label="First name" variant="outlined" sx={{ width: '48%' }} onChange={this.handleInputChange} />
            <TextField name="lastName" label="Last name" variant="outlined" sx={{ width: '48%', ml: '1.65rem !important' }} onChange={this.handleInputChange} />
          </Container>

          <TextField name="company" label="Company / Firm" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="school" label="School" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="phone" label="Phone" variant="outlined" fullWidth onChange={this.handleInputChange} />
          <TextField name="email" label="Email" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.emailError }</Typography>
          <TextField name="comment" label="Comment or Message" variant="outlined" multiline rows={4} fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.commentError }</Typography>

          <Button type="submit" variant="contained">Contact Us</Button>
          <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
        </Box>

      </Container>
    </section>;
  }
}
