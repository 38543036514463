import { Component } from 'react';
import { Container } from "@mui/material";

import './Footer.css';
import matchLogo from '../assets/LateralMatch_Logo_White.png';
import linkedIn from '../assets/linkedin-logo.png';


export default class Footer extends Component {
  render() {
    return <Container component="footer" disableGutters={true} sx={{
      padding: {
        xs: '1rem 1rem 0.25rem',
        sm: '1rem 1rem 0.25rem',
        md: '1rem 2rem 0.25rem',
        lg: '1rem 2rem 0.25rem',
        xl: '1rem 2rem 0.25rem' },
      maxWidth: '100% !important'
    }}>

      <div className="footer">
        <Container sx={{ display: { xs: 'none', sm: 'contents', md: 'contents', lg: 'contents', xl: 'contents' } }} disableGutters={true}>
          <div>
            <a href="/"><img className="match-logo" src={matchLogo} loading="lazy" alt="Lateral Match logo" width="120" height="57"/></a>
            <p className="motto">We Help Top Legal Talent</p>
            <p className="motto">Find Their Perfect Lateral Match</p>
          </div>
        </Container>
        <div>
          <h3>Services</h3>
          <Container component="div" className="vert-actions" sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem', lg: '1rem', xl: '1rem' }, display: 'flex', flexDirection: 'column', padding: '0' }} disableGutters={true} >
            <a href="/#">Home</a>
            <a href="/employers">Legal Employers</a>
            <a href="/submit">Submit Your Resume</a>
            <a href="/contact">Contact Us</a>
            <a href="/join">Join Us</a>
          </Container>
        </div>
        <div>
          <h3>About</h3>
          <Container component="div" className="vert-actions" sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem', lg: '1rem', xl: '1rem' }, display: 'flex', flexDirection: 'column', padding: '0' }} disableGutters={true} >
            <a href="/mission">Mission</a>
            <a href="/recruiters">Recruiters</a>
            <a href="/founders">Founder</a>
            <a href="/testimonials">Testimonials</a>
            <a href="/privacy">Privacy Policy</a>
          </Container>
        </div>
        <div>
          <h3>Offices</h3>
          <Container component="div" className="vert-actions" sx={{ fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem', lg: '1rem', xl: '1rem' }, display: 'flex', flexDirection: 'column', padding: '0' }} disableGutters={true} >
            <p className="office">Miami: (305) 330-4488</p>
            <p className="office">New York: (646) 374-4948</p>
            <p className="office">Chicago: (320) 493-3770</p>
            <p className="office">Dallas: (352) 222-2080</p>
            <p className="office">Washington DC: (202) 900-9955</p>
            <p className="office">Los Angeles: (310) 507-1101</p>
          </Container>
        </div>

        <Container sx={{ display: { xs: 'none', sm: 'none', md: 'contents', lg: 'contents', xl: 'contents' } }} disableGutters={true}>
          <div className="vert-actions">
            <a href="http://LinkedIn.com/company/lateral-match/" target="_blank" rel="noreferrer"><img src={linkedIn} loading="lazy" alt="LinkedIn" width="96" height="25"/></a>
          </div>
        </Container>

      </div>
      <div className="copyright">Copyright &copy; 2022-2023 Lateral Match LLC, All Rights Reserved</div>
    </Container>;

  }
}

