import React, { Component } from 'react';
import { Container } from "@mui/material";
import './Recruiters.css'


export default class Recruiters extends Component {
  render() {
    return <section className="lightgray recruiters-section">
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>
        <h1>Lateral Match Recruiters Have a Proven Track Record of Successfully Matching Candidates with Law Firms They Thrive in Long-Term</h1>
        <p>Our recruiters have placed attorneys at top law firms throughout the country. We pair our legal market expertise with an in-depth tailored analysis of each candidate’s background and goals to maximize their next career move.</p>

        <p>Our recruiters dig deep to learn as much as we can about each candidate – their goals, desires, skills, and personalities.</p>

        <p>Then, we draw upon our years of expertise and extensive research to deliver a short list of the best opportunities available for each candidate’s review.</p>

        <p>We think outside of the box to make the perfect match for each and every one of our candidates – we’ve placed candidates in BigLaw, elite boutique firms, in house, and even with solo practitioners!</p>

        <p>True story: one of our recruiters once placed a former BigLaw M&A associate, who very much wanted out of BigLaw, with a rural small-town solo practitioner, where she still practices sophisticated M&A (with much more reasonable hours!) today.</p>

        <p>Our team works with laterals of all kinds – associates, counsel, partners, and practice groups  - looking to make the next move in their career. Our recruiters have deep relationships with law firms and companies nationwide.</p>

      </Container>
    </section>;
  }
}

