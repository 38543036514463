import React, { Component } from 'react';
import { Container } from "@mui/material";
import './About.css';


export default class About extends Component {
  render() {
    return <section id="about" className="about">
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

      <h1>About Our Lateral Match Recruiters</h1>
      <p>
Lateral Match only hires the best to represent our candidates in finding positions that are the perfect match for them.
      </p>
      <p>
All of our recruiters went to top law schools, including Harvard Law School, and secured federal clerkships before joining <i>AmLaw</i>-ranked firms.  Before founding Lateral Match, our team spent years learning and perfecting their craft at two of the nation’s leading legal recruiting firms.
      </p>
      <p>
Our recruiters are experts in the legal recruiting field who keep informed about hiring trends across the country and all practice areas.
      </p>
      </Container>
    </section>;

  }
}

