import React, { Component } from 'react';
import { Container } from "@mui/material";

import './HomeFounders.css';


export default class HomeFounders extends Component {
  render() {
    const bgClass = this.props.bg || 'none';
    return <section className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

<h1>Lateral Match was Founded by Graduates of Top Law Schools, including Harvard, with Federal Clerkship, <i>AmLaw</i> 100 Firm, and Many Years of Legal Recruiting Experience</h1>

<p>Lateral Match was founded by alumni of top law schools, including Harvard Law School, who pursued federal clerkships and <i>AmLaw</i> 100 firm associate positions before using their skills and knowledge of the legal market to match top legal talent with the law firms that best suit them.</p>

<p>Before founding Lateral Match, our team spent years learning and perfecting their craft at two of the nation’s leading legal recruiting firms.</p>

<p>We believe that the best legal recruiters are really the best matchmakers.</p>

<p>Rather than reaching out to any firm with an opening, we dig deep to really get to know our candidates and place them at the firms that will best serve their needs and goals for years to come.</p>

<p>We only ever send our candidates’ materials to firms that they explicitly approve and that we know would be a great match for them.</p>

      </Container>
    </section>;

  }
}

