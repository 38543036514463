import React, { Component } from 'react';
import { red } from '@mui/material/colors';
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import ReactGA from 'react-ga4';


const defaultState = {
  location: null,
  jobType: null,
  practice: null,
  locationError: null,
  jobTypeError: null,
  practiceError: null,
  successMessage: null,
};

export default class Search extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  validate() {
    if (!this.state.location) {
      this.setState({ locationError: 'Location is required' });
      return false;
    } else
      this.setState({ locationError: null });

    if (!this.state.practice) {
      this.setState({ practiceError: 'Practice Area is required' });
      return false;
    } else
      this.setState({ practiceError: null });

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit: ', this.state);

    if (this.validate()) {
      const url = 'http://localhost:8000/search';
      const params = {
        location: this.state.location,
        jobType: this.state.jobType,
        practice: this.state.practice,
      };
      const payload = JSON.stringify(params);
      const req = new XMLHttpRequest();
      req.open('POST', url);
      req.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          // Request finished. Do processing here.
          console.log(`Search reponse: `, req.response);
          this.setState({ successMessage: 'Search complete' });

          ReactGA.event({
            category: 'Jobs',
            action: 'Search Jobs',
            label: payload,
          });
        }
      }
      req.send(payload);
    }
  }

  render() {
    const bgClass = this.props.bg || 'none';
    return <section id="search" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0 0', lg: '2rem 0 0', xl: '2rem 0 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

        <h1>Search for your Perfect Law Job</h1>
        <p>We have thousands of open legal positions at your fingertips.</p>
      </Container>

      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '0 2rem 2rem', sm: '0 2rem 2rem' },
        pb: '2rem',
        margin: '0 auto',
      }}>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: '0.5rem 0' } }}
          noValidate
          align="left"
          autoComplete="off"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <TextField name="location" label="Location" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.locationError }</Typography>

          <TextField name="jobType" label="Job Type" variant="outlined" fullWidth onChange={this.handleInputChange} />

          <TextField name="practice" label="Practice Area" variant="outlined" fullWidth required onChange={this.handleInputChange} />
          <Typography sx={{ color: red[900], mt: '0 !important' }}>{ this.state.practiceError }</Typography>

          <Button type="submit" variant="contained">Search</Button>
          <Typography sx={{ mt: '0 !important' }}>{ this.state.successMessage }</Typography>
        </Box>
      </Container>
    </section>;
  }
}

