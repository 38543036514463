import { Component } from 'react';
import { Container } from "@mui/material";
import Founders from '../components/Founders';
import bgImage from '../assets/boardroom.png';

export default class FoundersPage extends Component {
  render() {
    return <Container sx={{ height: 'calc(100vh - 16rem)', overflowY: 'auto',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <Founders />
      </Container>;
  }
}

