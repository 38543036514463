import { Component } from 'react';
import { Container } from "@mui/material";
import Mission from '../components/Mission';

export default class MissionPage extends Component {
  render() {
    return <Container sx={{ height: 'calc(100vh - 16rem)', overflowY: 'auto' }}>
        <Mission />
      </Container>;
  }
}

