import React, { Component } from 'react';
import { Nav, NavLink, NavMenu } from "./MenuBarElements";

export default class MenuBar extends Component {
  render() {
    return <Nav>
        <NavMenu>
          <NavLink to="/" end>Home</NavLink>
          <NavLink to="/mission">Mission</NavLink>
          <NavLink to="/employers">Legal Employers</NavLink>
          <NavLink to="/founders">Founder</NavLink>
          <NavLink to="/recruiters">Recruiters</NavLink>
          <NavLink to="/testimonials">Testimonials</NavLink>
          <NavLink to="/join">Join Us</NavLink>
        </NavMenu>
      </Nav>
  }
}

