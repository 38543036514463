import React, { Component } from 'react';
import { Container } from "@mui/material";
import Employers from '../components/Employers';


export default class EmployersPage extends Component {
  render() {
    return <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '1rem 2rem 2rem', sm: '1rem 2rem 2rem', md: '1rem 0 0', lg: '1rem 0 0', xl: '1rem 0 0' },
        margin: '0 auto 1rem',
        textAlign: 'left'
      }}>

        <Employers />
      </Container>;

  }
}

