import React, { useState } from 'react';
import './ImageCarousel.css';
import ExpandCircleDown from '@mui/icons-material/ExpandCircleDown';

export let carouselInterval = null;

export default function ImageCarousel({ images, rotateTime=5000 }) {
  const [x, setX] = useState(0);
  const [activeCount, setActiveCount] = useState(0);

  const slidesCount = images.length;

  const moveLeft = () => {
    clearTimer();
    if (x === 0) {
      setX(-100 * (slidesCount - 1));
      setActiveCount(slidesCount - 1);
    } else {
      setX(x + 100);
      setActiveCount((prevCount) => prevCount - 1);
    }
  };

  const moveRight = () => {
    clearTimer();
    if (x === -100 * (slidesCount - 1)) {
      setX(0);
      setActiveCount(0);
    } else {
      setX(x - 100);
      setActiveCount((prevCount) => prevCount + 1);
    }
  };

  const clearTimer = () => {
    clearInterval(carouselInterval);
    carouselInterval = null;
  }

  if (!carouselInterval) {
    carouselInterval = setInterval(() => moveRight(), rotateTime);
  }

  return (<div className="slider">
    <div className="slides-container">
      {images.map((data, index) => {
        return (
          <div style={{ transform: `translateX(${x}%)` }} key={index} className="slides" >
            <img src={data} width="100%" alt="Carousel" />
          </div>
        );
      })}
      <div className="slider-navigation">
        <ExpandCircleDown className="nav-btn nav-left" alt="Hero carousel back" onClick={moveLeft} />
        <div className="nav-indicator" onClick={moveRight}>
          {images.map((_, index) => (
            <span key={index} className={index === activeCount ? 'active-slide' : 'inActive-slide'} ></span>
          ))}
        </div>
        <ExpandCircleDown className="nav-btn nav-right" alt="Hero carousel forward" onClick={moveRight} />
      </div>
    </div>
  </div>);
}
