import React, { Component } from 'react';
import { Container } from "@mui/material";

import './Testimonials.css';


export default class Testimonials extends Component {
  render() {
    const bgClass = this.props.bg || 'none';
    return <section className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

        <h1>Testimonials and Reviews</h1>

<h3>Mark Riffle</h3>
<p>Indiana University Maurer School of Law<br/>
Indianapolis Real Estate Associate<br/>
Placed at Taft Stettinius & Hollister</p>

<p>I had a great experience working with Amanda Finley, Esq. of Lateral Match. Amanda did a tremendous job helping me find a new position in my local market. Amanda was diligent and was always reachable for questions, resume revisions, and interview preparation. I am thrilled with the outcome and couldn’t be happier with Amanda’s assistance.</p>

<h3>Ramsey Scofield</h3>
<p>Duke University School of Law<br/>
NY Bankruptcy and Restructuring Managing Associate<br/>
Placed at Linklaters LLP (NYC)</p>

<p>I had an excellent time working with Amanda Finley, who recently helped place me at an international law firm. She was extremely professional and responsive. Amanda really took an interest getting to know me as a candidate, and was genuinely interested in hearing and understanding my goals and feedback.</p>

<p>I felt Amanda was a strong advocate for me in the process when she could tell that I would be a good fit at a firm, and was always providing encouragement in the process when I needed it. She always offered a lot of very useful information about the roles I was interviewing for. She was very proactive in providing updates about the market which she shared even without me having to ask.</p>

<p>I would easily recommend Amanda Finley.</p>

<h3>Teresa Azzam</h3>
<p>Case Western Reserve University School of Law, J.D.<br/>
Universidad Pontificia Comillas in Spain LL.M<br/>
Washington D.C. Commercial Litigation Associate<br/>
Placed at Miles & Stockbridge</p>

<p>I had a wonderful experience working with Amanda Finley. Most of my work experience was based outside of the US, however I wanted to find a role in the DC area. Amanda was super diligent and helped me apply for countless roles that I never would have discovered on my own. Throughout the entire process, Amanda was always reachable for questions and even assisted me with my resume and interview prep. I always appreciated her kind and optimistic attitude in assisting me with all aspects of my job search. Eventually, Amanda helped me secure a position in an <i>AmLaw</i> 200 firm in the DC area. I am thrilled with this outcome and could not have done it without her help. I would highly recommend working with Amanda.</p>

<h3>Michael J. Harris</h3>
<p>Boston College Law School<br/>
NY Intellectual Property Litigation Associate<br/>
Placed at Bracewell LLP</p>

<p>It was my absolute pleasure to work with Amanda Finley, Esq., of Lateral Match, during my search for a new position as a litigation associate.  Using her in-depth knowledge of the market and experience in recruiting, Amanda successfully coordinated my transition from my prior role to a new position with a prestigious <i>AmLaw</i> 200 firm.  Amanda conducted herself with the highest degree of professionalism, and was responsive to and accommodating of all of my needs.  She was a valuable resource throughout my search for a new position, and I am grateful to have had the opportunity to work with her.  Thank you, Amanda!</p>

<h3>Commercial Litigation Attorney</h3>
<p>Placed at Holland & Knight LLP</p>

<p>After spending two months searching for new opportunities, I found a recruiting gem in Amanda Finley from Lateral Match. She helped me every step of the way. With her expertise, Amanda not only identified numerous opportunities I would not have otherwise found but also prepared me for interviews and explained the pros and cons of practice areas which was paramount in deciding where to move. With Amanda’s help, I jumped from a boutique law firm to an <i>AmLaw</i> 100 firm, where I enjoy a fantastic practice group immersed with expertise, willing to mentor a young attorney, and a healthy work environment. Thank you, Amanda, for your all your efforts.</p>

<h3>Chris Boyett</h3>
<p>University of Florida Levin College of Law<br/>
Miami Commercial Litigation Associate<br/>
Placed at Akerman LLP</p>

<p>I am so grateful I had Amanda Finley as my recruiter. She coached and guided me through a lengthy interview season with many top law firms and was helpful, thoughtful, and knowledgeable for the entirety of the process. She was always available for advice and help (night and day!), and was pivotal in navigating through a big transition. Her knowledge of the legal landscape and the process at large was impressive, and I hope that for any lateral move, associates can find someone as wonderful and helpful as Amanda!</p>

<h3>Katilee Boisvert</h3>
<p>Western New England College School of Law<br/>
Connecticut Commercial Finance Senior Associate<br/>
Placed at Shipman & Goodwin LLP</p>

<p>Amanda was wonderful to work with during my process of looking for a new opportunity. She was personable as well as very knowledgeable and extremely responsive. I would highly recommend her services to anyone.</p>

<h3>Ward Paschal</h3>
<p>University of Chicago Law School<br/>
Woodlands Texas Corporate Capital Markets Associate<br/>
Placed at Winstead</p>

<p>It was incredibly enjoyable working with Amanda Finley during my firm search. Despite opportunities being slim or slow for corporate attorneys, Amanda continually stayed on top of opportunities and regularly communicated with me throughout the process. She continued to expand the search at the appropriate times and provided valuable feedback throughout the application, interview, and callback process. She was incredibly positive and a joy to work with. Amanda was able to help me find a fantastic opportunity in Texas and her assistance was invaluable during a slow period for corporate attorney opportunities. I highly recommend working with Amanda!</p>


<h3>Rafael Amador</h3>
<p>University of Miami School of Law<br/>
Corporate Associate<br/>
Placed at Dickinson Wright PLLC (Fort Lauderdale)</p>

<p>I had an incredible experience working with Amanda Finley, Esq. of Lateral Match. Amanda went above and beyond during this process to find me a new position in the precise corporate scope of work where I want to develop my career. Given that she is an attorney with over ten years of experience working with renowned attorneys, she understands best what prestigious law firms seek from new associates. Amanda was able to provide me with fantastic insights and ideas to not only market me better but also highlight my strengths. Amanda helped me with my resume and several interview preparations to be ready and composed for my interviews. Her willingness and availability to meet and discuss strategic plans made her stand out and gave me a sense of calmness while searching for a new job opportunity.</p>

<p>I only have words of gratitude towards Amanda for paying close attention to my job-related goals and needs. I am lucky to have worked with Amanda. Without a shadow of a doubt, I highly recommend Amanda for the quality of her services and professionalism.</p>

      </Container>
    </section>;

  }
}

