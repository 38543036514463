import React, { Component } from 'react';
import { Container } from "@mui/material";
import './Mission.css';


export default class Mission extends Component {
  render() {
    const bgClass = this.props.bg || 'none';
    return <section id="mission" className={bgClass}>
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        textAlign: 'left'
      }}>

      <h1>Lateral Match’s Mission</h1>
      <p>We believe that successful lateral placements require an in-depth analysis of each individual candidate’s skillset, goals, and personality, coupled with extensive market research and deep industry expertise to make the perfect match.</p>
      <p>
We understand how busy our candidates are (we used to work at BigLaw firms, too!) and provide concierge service at every stage of the lateral process.
      </p>
      <p>
We will help you decide whether to lateral, evaluate available opportunities, communicate with firms, prepare for interviews, negotiate and accept or decline offers, navigate conflicts and the onboarding process, and answer any questions you might have along the way.
      </p>
      <p>
We work relentlessly for our candidates and will not stop until you obtain your dream job.  Making the decision to take the next step in your career can be hard, but we make everything beyond that easy.
      </p>
      </Container>
    </section>;
  }
}

