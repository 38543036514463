import { Component } from 'react';
import { Container, Link } from "@mui/material";

export default class NotFoundPage extends Component {
  render() {
    return (<>
      <Container sx={{ m: '2rem' }}>
        <h1>404 - Page Not Found</h1>
        <p style={{textAlign:"center"}}>
          <Link href="/" underline="hover">Go to Home</Link>
        </p>
      </Container>
    </>);
  }
}

