import { Component } from 'react';
import { Container } from "@mui/material";
import './PrivacyPage.css';


export default class PrivacyPage extends Component {
  render() {
    return <section className="privacy-section">
      <Container sx={{
        maxWidth: { xs: '100%', sm: '100%', md: 800, lg: 800, xl: 800 },
        padding: { xs: '2rem', sm: '2rem', md: '2rem 0', lg: '2rem 0', xl: '2rem 0' },
        margin: '0 auto',
        align: 'left'
      }}>

<h1>Privacy Policy</h1>
<p>lateral-match.com is committed to protecting any personal information that you provide to us. In particular, we believe it is important for you to know how we treat information about you that we may receive from this website.</p>

<h2>Information Collected</h2>
<p>In general, you can visit this website without telling us who you are or revealing any information about yourself. Our web servers collect the domain names, not the e-mail addresses, of visitors. Our web servers also seek (as many websites do) to place a “cookie” (a small data file) on your computer’s hard drive which allows the server to identify the computer when it visits again in order to track statistical information about navigation to and throughout certain areas of our site and to promotions on other sites. This cookie is not used to obtain your name or any personal data. The information that is tracked is used only for internal purposes, such as to improve site navigation and to measure the effectiveness of our promotional placements. It is not shared with anyone other than lateral-match.com affiliates and contractors who assist lateral-match.com in these efforts and who are bound to confidentiality. However, if you prefer not to accept cookies, you can set your browser to reject them or to alert you before one is placed.</p>

<p>In addition, there are portions of this website where we may need to collect personal information from you for a specific purpose, such as to provide you with certain information you request. The information collected from you may include your name, address, telephone, fax number, or e-mail address.</p>

<p>We use a third-party technology to place our advertisements on other websites. This technology will install a small “cookie” file on your computer when you view our ad. These cookies will not contain any information that personally identifies you (such as your name or e-mail address) but they will contain a randomly generated number that is unique to your browser and can be recognized by a “web beacon” (transparent GIF file) on our site if you click through to our site from one of our ads. This allows us to keep track of how many unique visitors we have to our site and from what ads they entered, so that we can measure the effectiveness of our ads and ad placements. The privacy policies of the websites on which we advertise, and through which the cookies are installed should inform you about the cookie, and you may of course set your browser to reject cookies. To learn more about the third-party ad-serving technology that we use, cookies, and how to “opt-out,” please email us.</p>

<p>This website is not intended for persons under 18 years of age. We do not knowingly solicit or collect personal information from or about children, and we do not knowingly market our products or services to children.</p>

<h2>Use of Collected Information</h2>
<p>By submitting a request form on any of the lateral-match.com web properties or online offers, I authorize lateral-match.com or their affiliates to contact me by telephone and/or email in respect to this offer and any new offers made available in the future.</p>

<p>Domain name information that we collect is not used to personally identify you and instead is aggregated to measure the number of visits, average time spent on the site, pages viewed, etc. We use this information to measure the use of our site and to improve the content of our site.</p>

<p>When other information is collected from you, such as your name and e-mail address, we generally let you know at the time of collection how we will use the personal information. Usually, we use the personal information you provide only to respond to your inquiry or to process your request (such as to receive information about our services). This information may be shared with other lateral-match.com businesses, but only if necessary to fulfill your request or for related purposes.</p>

<p>We may share the personal information you provide with other companies or affiliates. These companies are contractually bound to use personal information we share with them only to perform the services we have hired them to provide. We do not share, sell, or lease personal information about you to any outside third-parties for their marketing use. We will release information about you if you direct us to do so, if we are required by law to do so, or in other legally limited circumstances (for example, to protect your account from fraud).</p>

<p>If you register with one of lateral-match.com’s business units online, they may use this information to provide you with custom information about lateral-match.com’s offerings in support of your business needs. They may also seek to place a “cookie” (small data file) as discussed above in order to provide you with tailored information. You should review the privacy policy associated with that lateral-match.com business’ Web site for further information about that lateral-match.com business’ privacy practices.</p>

<h2>Phone Call Information</h2>
<p>If you call a telephone number listed on our website, we may collect Personal Information from you, including your name and telephone number through caller identification and reverse call technology. We also may record the telephone conversation. We record the telephone conversation to track the performance of our advertisements and for quality assurance purposes. We do not record the calls for the purpose of collecting your Personal Information. If we record the telephone conversation, we will notify you before the conversation begins. In addition to lateral-match.com, the only parties that have access to the recording are the telephone service provider and lateral-match.com contractors and affiliates who assist strictly in the efforts described in this paragraph, and who are bound by confidentiality.</p>

<p>By providing my contact information and telephone number, I expressly authorize lateral-match.com or their affiliates to contact me at the telephone number that I have provided and/or the email address that I have provided, even though I may have previously chosen to have the telephone number that I provided or the email address that I provided added to any Do-Not-Call List or anti-spam list including the lists maintained by lateral-match.com or their affiliates or any local, state, or federal government agency. I further acknowledge that by submitting this request, lateral-match.com or their affiliates have my permission to contact me via telephone and/or email.</p>

<h2>Links to Other Sites</h2>
<p>lateral-match.com’s website may contain links to other sites. While we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content, security, or privacy practices employed by other sites.</p>

<h2>Security of Collected Information</h2>
<p>We maintain strict physical, electronic, and administrative safeguards to protect your personal information from unauthorized or inappropriate access. We restrict access to information about you to those lateral-match.com workers who need to know the information to respond to your inquiry or request. Workers who misuse personal information are subject to disciplinary action.</p>

<h2>Contacting Us</h2>
<p>If you have any questions about this privacy policy, please email us. We welcome your questions and suggestions about our privacy policy.</p>

<h2>Changes to this Policy</h2>
<p>Please check this privacy policy periodically to inform yourself of any changes.</p>


      </Container>
    </section>;
  }
}

