import React, { Component } from 'react';
import Hero from '../components/Hero';
import HomeFounders from '../components/HomeFounders';
import Recruiters from '../components/Recruiters';
import Employers from '../components/Employers';
import About from '../components/About';
import Mission from '../components/Mission';


export default class Home extends Component {
  render() {
    return (
      <div>
        <Hero />
        <HomeFounders bg="reverse" />
        <Recruiters bg="lightgray" />
        <Employers />
        <Mission bg="reverse" />
        <About />
      </div>
    );
  }
}

