import { Component } from 'react';
import { Container } from "@mui/material";
import Search from '../components/Search';

export default class SearchPage extends Component {
  render() {
    return <Container>
        <Search />
      </Container>;
  }
}

