import React, { Component } from 'react'
import { Box, Button } from "@mui/material";

import './Upload.css'
import Dropzone from './Dropzone';


const defaultState = {
  files: []
};

export default class FileSelector extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState;

    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  render() {
    return (
      <div className="file-selector">
        <div>
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.files?.length}
          />
        </div>
        <div className="files">
          {this.state.files.map(file => {
            return (
              <Box key={file.name} className="row" sx={{ display: 'flex', flexDirection: 'row' }}>
                <span className="filename">{file.name}</span>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    this.setState({ files: [] })
                  }
                >
                  Clear
                </Button>
              </Box>
            );
          })}
        </div>
      </div>
    );
  }

  onFilesAdded(files) {
    if (files?.length) {
      this.setState(prevState => ({
        files: prevState.files.concat(files)
      }));
      if (this.props.onChange) {
        // this.state.files is not set yet
        const contents = [];
        for (let i=0; i < files.length; i++) {
          const file = files[i];
          let reader = new FileReader();
          reader.addEventListener('load', (event) => {
            contents.push(event.target.result);
// currently only works with one file
this.props.onChange(files, contents);
          });
          reader.readAsDataURL(file);
        }
      }
    }
  }

}
